<template>
  <span class="icon">
    <template v-if="animated">
      <Transition name="fade">
        <svg
          :key="name"
          :data-cy="name"
          :style="{
            fill: color,
            height: heightInternal,
            width: widthInternal,
            'margin-bottom': upInternal
          }"
          role="img"
          :aria-hidden="!altText ? true : undefined"
          :aria-labelledby="titleId"
          :viewBox="viewBoxInternal"
        >
          <title v-if="altText" :id="titleId" aria-hidden="true">
            {{ altText }}
          </title>
          <use :href="`#svg-${name}`"></use>
        </svg>
      </Transition>
    </template>
    <template v-else>
      <svg
        :key="name"
        :data-cy="name"
        :style="{
          fill: color,
          height: heightInternal,
          width: widthInternal,
          'margin-bottom': upInternal
        }"
        role="img"
        :aria-hidden="!altText ? true : undefined"
        :aria-labelledby="titleId"
        :viewBox="viewBoxInternal"
      >
        <title v-if="altText" :id="titleId" aria-hidden="true">
          {{ altText }}
        </title>
        <use :href="`#svg-${name}`"></use>
      </svg>
    </template>
  </span>
</template>

<script>
import { uniqueId } from 'src/lib/helpers'

export default {
  name: 'IconComponent',
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: undefined
    },
    height: {
      type: String,
      default: undefined
    },
    width: {
      type: String,
      default: undefined
    },
    up: {
      type: String,
      default: undefined
    },
    altText: {
      type: String,
      default: undefined
    },
    animated: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    titleId() {
      return this.altText ? `svg-title-${uniqueId()}` : undefined
    },
    heightInternal() {
      if (this.height) {
        return this.calcSize(this.height)
      }

      return undefined
    },
    widthInternal() {
      if (this.width) {
        return this.calcSize(this.width)
      }

      if (this.heightInternal) {
        return this.heightInternal
      }

      return undefined
    },
    upInternal() {
      if (this.up) {
        return this.up + 'px'
      }

      return undefined
    },
    viewBoxInternal() {
      if (
        this.height &&
        this.height.match(/^\d+.?\d*$/) &&
        this.width &&
        this.width.match(/^\d+.?\d*$/)
      ) {
        return `0 0 ${this.width} ${this.height}`
      } else {
        return '0 0 100 100'
      }
    }
  },
  methods: {
    calcSize(size) {
      if (size.includes('px') || size.includes('em') || size.includes('%')) {
        return size
      }

      return size + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;

  svg {
    height: 1em;
    width: 1em;
  }
}

.fade-enter-active {
  transition: opacity 0.2s 0.2s;
}

.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-from {
  opacity: 0;
  position: absolute;
}

.fade-leave-to {
  opacity: 0;
  position: absolute;
}
</style>
